<template>
<div>
  <HeaderView :page_index="4"></HeaderView>
  <el-dialog v-model="show.login" title=""  class="login_view" width="30%"  center :show-close="false" @closed="close_view"><LoginDialog /></el-dialog>
  <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left;" />
  <div v-else class="home">
    <el-container>
      <el-container>
        <el-aside width="200px">
          <div style="text-align: left; margin:20px 0px 10px 0px;" >
            <div style="margin: 10px 0px 10px 10px;"><el-input v-model="content1" :autosize="{ minRows: 5, maxRows: 100 }" type="textarea" placeholder="可输入多个人物，以顿号/换行间隔" /> </div>
            <div class="search_button" @click="search_words('word')" >搜索人物</div>
          </div>
          <!-- <div style="text-align: left; margin:20px 0px 10px 0px;" >
            <div style="margin: 10px 0px 10px 10px;"><el-input v-model="content2" :autosize="{ minRows: 5, maxRows: 100 }" type="textarea" placeholder="可输入多个人物，以顿号/换行间隔" /> </div>
            <div class="search_button" @click="search_words('desc')" >按描述搜索</div>
          </div> -->
        </el-aside>
        <el-container style="text-align: left; padding-left: 20px;">
          <el-skeleton v-if="loading3" :rows="5" animated style=" margin: 30px 30px 30px 20px;" />
          <el-main  v-else>
            <div class="tab2">
              <el-tag  v-for="(item, index)  in group_list" :key="index" @click="click_group_tab(index)" style="margin: 6px 6px 6px 0px; cursor:pointer;" type="info" :effect="index === current_group_index?'dark':'plain'">{{item.name}} {{item.num}}</el-tag>
            </div>
            <el-skeleton v-if="loading2" :rows="5" animated  style="text-align: left;" />
            <div v-else>
              <OnePersonCanPull :person_info="info" tag="middle" :action="'goTo'" v-for="(info, index) in list" :key="index"  />

              <div v-if="group_list.length!==0" class="footer">
                <el-pagination v-if="group_list[current_group_index].page!==0" @current-change="get_list" :current-page="current_page"  layout="prev, pager, next" :page-count="group_list[current_group_index].page"></el-pagination>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import OnePersonCanPull from '@/components/items/OnePersonCanPull'
import HeaderView from '@/components/other/HeaderView'
import LoginDialog from '@/components/other/LoginDialog'

export default {
  name: 'Person_search',
  mounted () {
    this.get_index()
  },
  components: {
    OnePersonCanPull, HeaderView, LoginDialog
  },
  data () {
    return {
      loading: false,
      loading2: false,
      loading3: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      group_list: [],
      list: [],
      content1: '',
      content2: '',
      group_tags: [['group', 'group'], ['my', 'my'], ['net', 'net']],
      paixus: [['频次', 'num'], ['字母', 'zimu'], ['长度', 'length'], ['出生', 'year_birth']],
      in_mys: [['不介意', null], ['yes', 1], ['not', 0], ['未标', '-']],
      fixs: [['不介意', null], ['yes', 1], ['not', 0], ['未标', '-']],
      directions: [['不限制', '-'], ['大于', 'greater'], ['小于', 'less']],
      person_states: [['不介意', null], ['保留', 1], ['删除', 0]],
      current_group_index: 0,
      current_page: 1,
      person_paixu: 'num',
      see_want: 'net',
      searchTag: 'word',
      show: {}
    }
  },
  methods: {
    get_index () {
      if (localStorage.getItem('search_person_paixu')) {
        this.person_paixu = localStorage.getItem('person_paixu')
      }
    },
    search_words (searchTag) {
      this.loading3 = true
      this.list = []
      this.current_page = 1
      this.current_group_index = 0
      this.searchTag = searchTag
      if (searchTag === 'word') {
        this.content = this.content1
        this.content2 = ''
      } else {
        this.content = this.content2
        this.content1 = ''
      }
      if (this.content.length < 2) {
        Utils.alert('请输入两个或以上汉字')
        this.loading3 = false
        return
      }

      if (Utils.check_is_niming()) {
        this.loading3 = false
        this.show.login = true
        return
      }
      axios.post('/search_person_index/', { paixu: this.person_paixu, content: this.content, group_tag: this.group_tag, search_tag: this.searchTag })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            this.loading3 = false
            return
          }
          this.loading3 = false
          this.list = res.data.data.list
          this.group_list = res.data.data.group_list
        })
    },

    get_list  (e) {
      if (Utils.check_is_niming()) {
        this.loading3 = false
        this.show.login = true
        return
      }
      this.current_page = e
      this.loading2 = true
      window.scrollTo(0, 0)
      axios.post('/search_person_list/', { group: this.group_list[this.current_group_index].value, page: this.current_page, paixu: this.person_paixu, content: this.content, search_tag: this.searchTag })
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            this.loading3 = false
            return
          }
          this.list = res.data.data.list
        })
    },
    click_group_tab (e) {
      if (this.current_group_index !== e) {
        this.current_group_index = e
        this.current_page = 1
        if (this.group_list[this.current_group_index].num === 0) {
          this.list = []
          return
        }
        this.loading2 = true
        this.get_list(1)
      }
    },
    set_group_tag (e) {
      console.log(e)
      if (e !== null) {
        localStorage.setItem('group_tag', e)
      } else {
        localStorage.removeItem('group_tag')
      }
      this.group_tag = e
      this.refresh_quality_tab_list()
    },
    open_sw (e) {
      window.open(store.state.base_url + e, '_blank')
    },
    set_paixu (e) {
      localStorage.setItem('search_person_paixu', e)
      this.person_paixu = e
      this.refresh_quality_tab_list()
    }
  }
}
</script>
<style scoped>
.tab2{
  display: block;
  text-align: left;
  padding: 10px 40px 30px 0px;
}
.tab2 .el-tag,.tab2  .el-dropdown{
  vertical-align: middle;
}

.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}

.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
.search_button{
  background: #50b7c1;
  text-align: center;
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
  color:#fff;
  font-size: 14px;
  margin: 10px 10px;
  cursor:pointer;
}
.el-textarea .el-textarea__inner:focus{
  border-color: #50b7c1;
}
</style>
